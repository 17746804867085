body {
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana;
  font-size: 13px;
}
p {
  font-size: 13px;
}
h1 {
  color: #003e5c;
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
}
h2 {
  color: #003e5c;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
}
.ar {
  text-align: right;
}
.ac {
  text-align: center;
}
.row {
  margin-bottom: 16px;
}
.ColorBlue {
  color: #003e5c;
}
.ColorAqua {
  color: #00aec3;
}
.ColorGreen {
  color: #95c11f;
}
.ColorYellow {
  color: #ffd600;
}
.ColorrOrange {
  color: #ef7d00;
}
.LinkThemeOrange > div {
  background-color: #ef7d00;
}
.LinkThemeOrange > div > div {
  border: 3px solid #ef7d00;
}
.LinkThemeOrange .button {
  background-color: #ef7d00;
  border-color: #ef7d00;
}
.LinkThemeYellow > div {
  background-color: #ffd600;
}
.LinkThemeYellow > div > div {
  border: 3px solid #ffd600;
}
.LinkThemeYellow .button {
  background-color: #ffd600;
  border-color: #ffd600;
}

.LinkThemeGreen > div {
  background-color: #95c11f;
}
.LinkThemeGreen > div > div {
  border: 3px solid #95c11f;
}
.LinkThemeGreen .button {
  background-color: #95c11f;
  border-color: #95c11f;
}

.LinkThemeAqua > div {
  background-color: #00aec3;
}
.LinkThemeAqua > div > div {
  border: 3px solid #00aec3;
}
.LinkThemeAqua .button {
  background-color: #00aec3;
  border-color: #00aec3;
}
.LinkThemeBlue > div {
  background-color: #003e5c;
}
.LinkThemeBlue > div > div {
  border: 3px solid #003e5c;
}
.LinkThemeBlue .button {
  background-color: #003e5c;
  border-color: #003e5c;
}

.KorianRectLinkContainer {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  margin-top: -25px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.KorianRectLink {
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  padding: 17px;

  position: relative;
  left: -20px;
  top: -20px;
  /*flex-wrap: wrap;*/
}
.KorianRectLinkIconContainer {
  margin-right: 16px;
}
.KorianRectLinkIcon {
  color: #444;
  font-size: 36px;
  padding-top: 16px;
}

.title {
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
}

.subTitle {
  font-size: 13px;
  font-family: inherit;
}

.button {
  font-size: 13px;
  padding: 14px 20px;
  font-weight: 400;
  -webkit-transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -ms-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
  text-decoration: none;
  display: inline-block;
  .label {
    color: #fff;
  }
}
.button:hover {
  color: #fff;
  font-weight: 500;
}
.button > i{
    margin-left: -5px;
    margin-right: 6px;
    vertical-align: -4px;
    font-size: 25px;
    color: #fff;
}
.korianLogo{
    margin: 4px;
    height: 128px;
}
.imgArea > img {
  width: 100%;
  border-top-left-radius: 32px;
}

.blocks{
  display: flex;
}
.blocks > div{
  max-width: 480px;
}
.contentline{
  margin-bottom: 8px;
}

.navbarActions{
  display: flex;
  gap: 0.5em;
  margin-right: 15px;
}
.navbarActions > div{
  cursor: pointer;
  padding: 4px
}

@media screen and (max-width: 600px) {
  .imgArea {
    display: none;
  }
  h1 {
    color: #003e5c;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
  }
  h2 {
    color: #003e5c;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
  }
  .blocks > div{
    max-width: 100%;
  }

  .KorianRectLinkContainer{
    margin-top: 10px;
    margin-left: 20px;
  }
  .contentline{
    min-height: 0px;
  }
}